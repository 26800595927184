import { Autocomplete, Box, CircularProgress, TextField, ThemeProvider, Typography, createTheme } from '@mui/material'
import React, { useEffect, useState } from 'react'
import LocationOnIcon from '@mui/icons-material/LocationOn';
import HotelIcon from '@mui/icons-material/Hotel';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import { getIBE_API } from '../getAPIUrl';
import { createGlobalStyle } from "styled-components";
enum locationType {
  country,
  city,
  property,
  pincode
}
interface ILocation {
  location?: string;
  id?: string;
  type: locationType;
}
const removeDuplicateFromArray = (arrayItems: any[]) => {
  const jsonObject = arrayItems.map(x => JSON.stringify(x));
  const uniqueSet = new Set(jsonObject);
  return Array.from(uniqueSet).map(y => JSON.parse(y));
}
function SearchControl(props: any) {
  const { brandID, chainID, touched, errors, setFieldValue, setMaxChildAge, fontColor, setDestinationList, values, booknowbuttoncolor, booknowbuttonhovercolor, booknowbuttonfontcolor, buttonHoverFontColor, backgroundColor, setPageLoaded, setEventType, eventType} = props
  const [response, setResponse] = useState<any>([]);
  const [options, setOptions] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [inFocus, setInFocus] = useState(false);
  useEffect(() => {
    setLoading(true);
    const fetchLocationData = async () => {
      // TODO : fetch URL from config
      const IBE_lOCATION_API= getIBE_API();

      fetch(`${IBE_lOCATION_API}/Search/getLocationInfo?brandID=${brandID}&chainID=${chainID}`)
        .then((response: any) => response.json())
        .then((data) => {
          if (data?.status) {
            let locations: ILocation[] = [];
            setMaxChildAge(data?.body[0]?.childAgeLimit || 17)


            const response = data.body;
            setResponse(response)
            // Extract countries
            Array.from(new Set(response.map((item: any) => item.countryId))).map(countryId => {
              const country = response.find((item: any) => item.countryId === countryId);
              locations.push({ id: country.countryId, location: country.country, type: locationType.country });

              // Extract cities
                Array.from(new Set(response.map((item:any) => item.cityId))).map(cityId => {
                  const city = response.find((item:any) => item.cityId === cityId &&  item.countryId === countryId);
                  if(city){
                    locations.push( { id: city?.cityId, location: city.city, type: locationType.city });

                    // Extract property names
                    Array.from(new Set(response.map((item:any) => item.propertyId))).map(propertyId => {
                      const properties = response.filter((item:any) => item.cityId === cityId &&  item.countryId === countryId);

                      if(properties.length>0){
                        properties.map((p: any)=>{
                          locations.push( { id: p?.propertyId, location: p?.name, type: locationType.property });
                        })
                      }
                    })
                  }
                });
                //output.push(...cities);

            });
            locations = removeDuplicateFromArray(locations);
            setDestinationList(response)
            if(response.length === 1) {
              const selectedProperty = locations.find(x => x.id === response[0].propertyId);
              onChangeAutoComplete('', selectedProperty, setFieldValue, touched)
            }

                setOptions(locations);
                // setLocations(locations)
                setLoading(false);
              }
            }
        );
    }
    fetchLocationData();
  }, []);

  useEffect(() => {
    const loadMHSDetailData = async () => {
      const IBE_lOCATION_API = getIBE_API();
      fetch(`${IBE_lOCATION_API}/Search/GetMHSPropID?propId=${response[0].propertyId}`)
        .then((result: any) => result.json())
        .then((data) => {
          if (data?.body?.mhsPropertyID !== null && data?.body?.mhsPropertyID !== '') {
            (function (d: Document, s: string) {
              const scriptUrl = `https://d.myhotelshop.com/js/${data?.body?.mhsPropertyID}`;
              const existingScript = d.querySelector(`script[src="${scriptUrl}"]`);
              if (!existingScript) {
                const f = d.getElementsByTagName(s)[0];
                const j = d.createElement(s) as HTMLScriptElement;
                j.async = true;
                j.src = scriptUrl;
          
                if (f && f.parentNode) {
                  f.parentNode.insertBefore(j, f);
                } else {
                  // If no <script> tag is found, append to <head> or <body>
                  (d.head || d.body).appendChild(j);
                  console.log("Script added to head or body.");
                }
              }
            })(document, 'script');
          }
        });
    }
    if (response.length > 0) {
      loadMHSDetailData();
    }
  }, [response])

  useEffect(()=>{
    if(eventType!='' && inFocus){
      setOpen(true);
    }else{
      setOpen(false);
    }
  }, [eventType, inFocus])

  const styles = {
    '& .MuiFormLabel-filled': {
      color: '#9ca5ac !important'
    },
    '& label.Mui-focused': {
      color: 'green'
    },
    '& .MuiAutocomplete-popupIndicator': {
      display: 'none'
    },
    '& .MuiOutlinedInput-root': {
      paddingRight: '40px',
      '& fieldset': {
        borderColor: 'transparent',
        border: 'none',
        color: '#9ca5ac'
      },
      '&:hover fieldset': {
        borderColor: 'transparent',
        border: 'none',
        color: '#9ca5ac'
      },
      '&.Mui-focused fieldset': {
        //borderColor: 'white',
        border: '0px',
        color: '#9ca5ac'
      },
      'input': {
        '&::placeholder': {
          fontSize: '14px !important',
          fontWeight: 300,
          color: `${fontColor} !important`,
          opacity: 1
        }
      }
    },
    '&.MuiAutocomplete-root .MuiAutocomplete-clearIndicator': {
      color: `${fontColor} !important `,
      backgroundColor: `inherit !important`,
      padding : '0px !important'
    },
    '&.MuiAutocomplete-clearIndicator:hover':{
      backgroundColor: `inherit !important`,
    },
    '&.MuiButtonBase-root-MuiIconButton-root-MuiAutocomplete-clearIndicator': {
      color: `${fontColor} !important `
    },
    '&.MuiAutocomplete-root .MuiAutocomplete-clearIndicator .MuiSvgIcon-root': {
      color: `${fontColor} !important `
    },
  }

  const onChangeAutoComplete = (event: any, value:any, setFieldValue:any, touched:any)=> {
    touched.destination = {
      location: true,
      type:true
    }
    setPageLoaded(true);
    setFieldValue('destination', value, true);
    setInFocus(false);
  }

  const theme = createTheme({
    components: {
      MuiAutocomplete: {
        styleOverrides: {
          paper: {
            // Increase the width of the options container
            width: 375, // Specify your desired width here
            left:'30px',
            marginLeft:'-20px',
            marginTop:'-3px'
          },
          input:{
            color: `${fontColor} !important`,
          },
        },
      },
    },
  });

  const filterOptions = (options: any, { inputValue }: any) => {
    if (inputValue === "") {
      // setOpenSearch(false)
      return options?.filter((option: any) => {
        return option?.type !== locationType.pincode}
        )
    }
    return options?.filter((option: any) =>
      option?.location?.toLowerCase()?.includes(inputValue?.toLowerCase())
    );
  };

  const renderOption = (props: any, option: any, { selected }: any) => (
    <li {...props} style={{borderBottom:'1px solid #f6f7f7',paddingRight:'10px'}}>
      {option?.type === locationType.property ?

      <Box display={'flex'}>
        <HotelIcon sx={{color:'grey',fontSize:'18px'}} />
        <Box display={'flex'} flexDirection={'column'}  pl={2} width={'300px'}>
          <Typography sx={{color:'black', fontWeight:'450 !important',fontSize:'16px',lineHeight: '1', }}>{getPropertyCountry(option?.location)}</Typography>
          <Typography sx={{color:'#b3b0b0', fontSize:'13px'}}>Property</Typography>
        </Box>
      </Box>
      :
      option?.type === locationType.city ?
      <Box display={'flex'}>
        <LocationCityIcon sx={{color:'grey', fontSize:'18px'}} />
        <Box display={'flex'} flexDirection={'column'}  pl={2} width={'300px'}>
          <Typography sx={{color:'black', fontWeight:'500!important',fontSize:'16px',lineHeight: '1'}}>{option?.location}</Typography>
          <Typography sx={{color:'#b3b0b0', fontSize:'13px'}}>City</Typography>
        </Box>
      </Box>
        :
      <Box display={'flex'}>
        <LocationOnIcon sx={{color:'grey',fontSize:'20px'}} />
        <Box display={'flex'} flexDirection={'column'}  pl={2}>
          <Typography sx={{color:'black', fontWeight:'500!important',fontSize:'16px',lineHeight: '1'}}>{option?.location}</Typography>
          <Typography sx={{color:'#b3b0b0', fontSize:'13px'}}>Country</Typography>
        </Box>
      </Box>
      }
    </li>
  );

  const GlobalStyles = createGlobalStyle`
    .MuiPaper-root{
      display: ${open?"block":"none"}
    }
    .MuiAutocomplete-listbox {
      cursor: pointer
    }
    .MuiAutocomplete-listbox .MuiAutocomplete-option[aria-selected="true"] {
      background-color: ${booknowbuttoncolor} !important;
    }
    .MuiAutocomplete-listbox .MuiAutocomplete-option[aria-selected="true"] p, .MuiAutocomplete-listbox .MuiAutocomplete-option[aria-selected="true"] svg {
      color: ${booknowbuttonfontcolor} !important;
    }
    .MuiAutocomplete-listbox .MuiAutocomplete-option.Mui-focused p, .MuiAutocomplete-listbox .MuiAutocomplete-option.Mui-focused svg {
      color: ${buttonHoverFontColor} !important;
    }
    .MuiAutocomplete-listbox .MuiAutocomplete-option.Mui-focused {
      background-color: ${booknowbuttonhovercolor} !important;
    }
  `

  function getPropertyCountry(location: any): React.ReactNode {
    const country = response.find((x: any)=>x.name.toLocaleLowerCase() ===location.toLocaleLowerCase())?.country;
   return <Typography>{location}, <b>{country}</b>
   </Typography>
  }

  const renderInput = (params: any) => (
    <TextField
      {...params}
      placeholder='ENTER DESTINATION'
      required={true}
      error={Boolean(touched.destination && errors.destination)}
      InputProps={{
        ...params.InputProps,
        endAdornment: params.inputProps.value && (
          <>
            {loading && <CircularProgress size={20} />}
            {params.InputProps.endAdornment}
          </>
        ),
      }}
    />
  );

  return (
    <Box onClick={()=>setInFocus(!inFocus)}>
      <GlobalStyles />
      <ThemeProvider theme={theme}>
        <Autocomplete
          // componentsProps={{ popper: { style: { width: '370px' } } }}
          open={true}
          sx={{
            ...styles, position: 'relative', top: '-8px', left: '-10px', border: 'none', '&:hover': {
              'border': 'none'
            }
          }}
          value={values?.destination}
          noOptionsText={'No Location Found'}
          onChange={(event, value) => onChangeAutoComplete(event,value, setFieldValue, touched)}
          onBlur={() => {setOpen(false);setInFocus(false); setEventType("");}}
          options={options}
          getOptionLabel={(option) => option.location}
          filterOptions={filterOptions}
          renderInput={renderInput}
          renderOption={renderOption}

        />
      </ThemeProvider>
  </Box>
  )
}

export default SearchControl